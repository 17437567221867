import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React, { Component } from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link, withRouter } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import MaxWidth from '../Layout/MaxWidth';
import TrendButton from '../ui/Button';
import { smallCaps } from '../ui/Headings';
import { activeSegment, smallSection } from './UI/Form';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    flex: 1 1 calc(50% - 1em);
  }
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: #000;
  text-align: right;
  font-size: 0.875em;
  transition: all ease 0.3s;
  :hover {
    opacity: 0.6;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

class LogInPage extends Component {
  render() {
    return (
      <MaxWidth style={{ alignItems: 'center', marginTop: '2em' }}>
        <LogInFormProvider redirect="my-pages">
          {({ globalError, isSubmitting, isValid }) => (
            <section className={cx(smallSection, activeSegment)}>
              <header
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '0.75rem'
                }}
              >
                <h1 className={smallCaps} style={{ marginBottom: 0 }}>
                  {t('Login')}
                </h1>

                <TextLink to="/signup" className="signup-link">
                  {t('Not yet a member? Sign Up')}
                </TextLink>
              </header>
              <Input type="email" name="email" label={t('E-mail address')} />
              <Input type="password" name="password" label={t('Password')} />

              {globalError && (
                <GlobalError style={{ marginBottom: '2em' }}>
                  {globalError}
                </GlobalError>
              )}

              <section className={actionWrapper}>
                <StyledTrendButton
                  type="submit"
                  disabled={!isValid}
                  loading={isSubmitting}
                  loadingText={t('Hold on a moment...')}
                >
                  {t('Log in')}
                </StyledTrendButton>
                <TextLink to="/forgot-password">
                  {t('Forgot password?')}
                </TextLink>
              </section>
            </section>
          )}
        </LogInFormProvider>
      </MaxWidth>
    );
  }
}
export default withRouter(LogInPage);
