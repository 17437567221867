import { theme } from '../../Theme';

export const smallSection = `
  display: flex;
  flex-direction: column;
  padding: ${theme.space[1]};
  max-width: 100%;
  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  ${theme.below.sm} {
    width: 100%;
  }
`;

export const activeSegment = `
  position: relative;
  border: 1px solid #eee;
  padding: 1rem;
  ::before {
    content: '';
    position: absolute;
    left: -1.5rem;
    top: 0;
    bottom: 0;
    width: 3px;
    height: 100%;
    background: none;
    transition: opacity 0.4s ease;
  }
  ${theme.above.sm} {
    width: 40rem;
  }
`;
