import React from 'react';
import UIButton from '@jetshop/ui/Button';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import { theme } from '../Theme';

const TrendButtonStyle = `
  background-color: #000;
  border: none;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  :hover {
    background: #333;
  }
  ${theme.above.md} {
    max-width: 100%;
  }
`;

const Button = styled(UIButton)`
  ${TrendButtonStyle};
  font-family: 'Heldane Regular', serif;
  &.secondary {
    background-color: white;
    border-color: black;
    color: black;
  }

  &.disabled {
    background: #ffffff;
    border: 0.75px solid #dedede;
    color: #808080;
    transition: all ease 0.3s;
    :hover {
      background: #f7f7f7;
    }
  }
`;

export const TrendLink = styled(Link)`
  ${TrendButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

const ButtonWithLoading = ({ loading, loadingText, ...props }) =>
  loading ? (
    <Button>
      {loadingText}
      <Spinner />
    </Button>
  ) : (
    <Button
      {...props}
      className={cx(
        props.secondary && 'secondary',
        props.disabled && 'disabled',
        props.className
      )}
    />
  );

export default ButtonWithLoading;
